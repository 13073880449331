import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import ClickableMarker from "./ClickableMarker";
import { ControlPoint, ControlPointSelection } from "../../../features/controlPoint/dataTypes";
import styles from "./Map.module.scss";

const Map = ({
  controlPoints,
  onSelect,
  defaultZoom,
  selection,
}: {
  controlPoints: Array<ControlPoint>;
  onSelect: (id: string) => void;
  defaultZoom: number;
  selection: Array<ControlPointSelection>;
}) => {
  const createMapOptions = function () {
    return {
      mapTypeControl: true,
      mapTypeId: "satellite",
    };
  };

  let sumLat = 0;
  let sumLong = 0;

  for (const controlPoint of controlPoints) {
    sumLat = sumLat + Number(controlPoint.latitude);
    sumLong = sumLong + Number(controlPoint.longitude);
  }

  const meanLat = controlPoints.length > 0 ? sumLat / controlPoints.length : 55.59913974398411;
  const meanLong = controlPoints.length > 0 ? sumLong / controlPoints.length : 12.99912806970547;

  return controlPoints.length > 0 ? (
    <div className={styles.mapContainer}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GAPI_KEY ? process.env.REACT_APP_GAPI_KEY : "",
        }}
        zoom={defaultZoom}
        options={createMapOptions}
        center={{ lat: meanLat, lng: meanLong }}
      >
        {controlPoints.map(point => {
          const selectedColor = selection.find(sel => sel.id === point.id)?.color;

          return (
            <ClickableMarker
              key={point.id}
              lat={point.latitude}
              lng={point.longitude}
              clickHandler={() => onSelect(point.id)}
              label={point.name}
              selected={selection.findIndex(sel => sel.id === point.id) !== -1}
              hasPassages={point.hasPassages}
              scaling={point.scaling}
              selectedColor={selectedColor ? selectedColor : "white"}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  ) : null;
};

Map.propTypes = {
  controlPoints: PropTypes.array,
  onSelect: PropTypes.func,
  selectedIds: PropTypes.array,
} as unknown;

export default Map;
