import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { developmentAreaSliceName } from "./dataTypes";
import { developmentAreaAdapter } from "./developmentAreaAdapter";

const selectSliceState = (state: RootState) => state[appDataName][developmentAreaSliceName];

const adapterSelectors = developmentAreaAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessage;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectCount = (state: RootState) => selectSliceState(state).count;

export const selectDevelopmentAreaEntities = adapterSelectors.selectEntities;
export const selectDevelopmentAreas = adapterSelectors.selectAll;
export const selectDevelopmentArea =
  (id = "") =>
  (state: RootState) =>
    adapterSelectors.selectById(state, id);
