import { useState, useCallback, useMemo, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslate } from "../../language/i18n";

import styles from "./TrafficNodesPage.module.scss";

import Map from "../shared/Map/Map";
import { InputField } from "@myloc/myloc-gui";
import { Button } from "@myloc/myloc-gui";
import { Checkbox } from "@myloc/myloc-gui";

import SelectField from "../shared/SelectField/SelectField";
import ControlPointGraph from "./Graph/ControlPointGraph";
import MultiControlPointGraph from "./Graph/MultiControlPointGraph";
import AllControlPointsGraph from "./Graph/AllControlPointsGraph";
import ControlPointPassageTable from "./Passages/ControlPointPassageTable";

import useLazyGetDevelopmentAreas from "../../features/developmentArea/hooks/useLazyGetDevelopmentAreas";
import useGetControlPoints from "../../features/controlPoint/hooks/useGetControlPoints";
import useGetControlPointPassages from "../../features/controlPointPassage/hooks/useGetControlPointPassages";
import { useAppSelector } from "../../app/hooks";
import { selectDevelopmentAreaId } from "../../features/session/sessionSelectors";

import { DATA_TYPE, FULFILLED_UPDATE_METHOD } from "../../features/dataTypes";
import { ControlPointPassagesFilter } from "../../features/controlPointPassage/dataTypes";
import { ControlPointSelection } from "../../features/controlPoint/dataTypes";

type FilterType = Pick<ControlPointPassagesFilter, "fromDate" | "toDate">;

const FILTERS: readonly (keyof ControlPointPassagesFilter)[] = ["freeTextSearch", "all"] as const;

const TRAFFIC_NODE_COLORS = [
  "#8dd3c7",
  "#ffffb3",
  "#bebada",
  "#fb8072",
  "#80b1d3",
  "#fdb462",
  "#b3de69",
  "#fccde5",
  "#d9d9d9",
  "#bc80bd",
  "#ccebc5",
  "#ffed6f",
] as const;

const TrafficNodesPage = () => {
  const translate = useTranslate();
  const location = useLocation();

  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const controlPointParameter = params.get("controlPoint");

  const [hideEmptyControlPoint, setHideEmptyControlPoint] = useState(false);
  const [showGraph, setShowGraph] = useState(true);
  const [graphShowTotal, setGraphShowTotal] = useState(false);
  const [controlPointSelection, setControlPointSelection] = useState<Array<ControlPointSelection>>([]);
  const [developmentAreaId, setDevelopmentAreaId] = useState<string | undefined>(
    useAppSelector(selectDevelopmentAreaId),
  );

  const getChoicesFromParams = useCallback(() => {
    const filters = FILTERS.reduce(
      (choice, key) => ({
        ...choice,
        [key]: params.get(key) ?? "",
      }),
      {} as ControlPointPassagesFilter,
    );

    filters.toDate = new Date().toISOString().substring(0, 10);
    filters.fromDate = new Date().toISOString().substring(0, 10);
    filters.developmentAreaId = developmentAreaId;
    filters.all = true;

    return filters;
  }, [params, developmentAreaId]);

  const [filters, setFilters] = useState(getChoicesFromParams);

  const [multipleDates, setMultipleDates] = useState(false);

  const { controlPoints, refetchControlPoints } = useGetControlPoints({
    filter: { developmentAreaId: developmentAreaId, all: true },
    fulfilledUpdateMethod: FULFILLED_UPDATE_METHOD.SET_ALL,
  });

  const { developmentAreas } = useLazyGetDevelopmentAreas({
    filter: { all: true },
    fulfilledUpdateMethod: FULFILLED_UPDATE_METHOD.SET_ALL,
    loadIfNotInitialized: true,
  });

  useEffect(() => {
    if (controlPointParameter) {
      const initialControlPoint = controlPoints.find(controlPoint => controlPoint.code == controlPointParameter);

      if (initialControlPoint) {
        setControlPointSelection([
          ...controlPointSelection,
          { id: initialControlPoint.id, controlPoint: initialControlPoint, color: "blue" },
        ]);
      }
    }
  }, [controlPoints, controlPointParameter, controlPointSelection]);

  const filterChanged = useRef(false);
  const developmentAreaIdChanged = useRef(false);

  const { controlPointPassages, refetchControlPointPassages } = useGetControlPointPassages({
    filter: filters,
    fulfilledUpdateMethod: FULFILLED_UPDATE_METHOD.SET_ALL,
  });

  const availableColors = TRAFFIC_NODE_COLORS.filter(color => !controlPointSelection.find(sel => sel.color === color));
  const selectedDevelopmentArea = developmentAreas.find(developmentArea => developmentArea.id === developmentAreaId);

  const defaultZoomLevel = selectedDevelopmentArea?.defaultZoomLevel ? selectedDevelopmentArea.defaultZoomLevel : 16;

  useEffect(() => {
    filterChanged.current = true;
  }, [filters]);

  useEffect(() => {
    if (filterChanged.current) {
      filterChanged.current = false;
      refetchControlPointPassages();
    }
  }, [filters, refetchControlPointPassages]);

  useEffect(() => {
    developmentAreaIdChanged.current = true;
  }, [developmentAreaId]);

  useEffect(() => {
    if (developmentAreaIdChanged.current) {
      developmentAreaIdChanged.current = false;
      refetchControlPoints();
    }
  }, [developmentAreaId, refetchControlPoints]);

  const onFilterChange = (key: keyof FilterType, value: string) => {
    setFilters(currFilters => ({ ...currFilters, [key]: value }));

    if (!multipleDates && key === "fromDate") {
      setFilters(currFilters => ({ ...currFilters, toDate: value }));
    }
  };

  const onDateIntervalChange = () => {
    const oldMultipleDates = multipleDates;

    setMultipleDates(multipleDates => !multipleDates);

    if (oldMultipleDates) {
      setFilters(currFilters => ({ ...currFilters, toDate: currFilters.fromDate }));
    }
  };

  const onShowGraphToggle = () => {
    setShowGraph(!showGraph);
  };

  const onMapSelect = (id: string) => {
    // Deselect if selected
    if (controlPointSelection.find(selection => selection.id == id)) {
      setControlPointSelection(controlPointSelection.filter(selection => selection.id !== id));
    } else {
      const newControlPoint = controlPoints.find(controlPoint => controlPoint.id === id);

      if (newControlPoint) {
        const nextColor =
          availableColors.length > 0
            ? availableColors[0]
            : TRAFFIC_NODE_COLORS[controlPointSelection.length % TRAFFIC_NODE_COLORS.length];

        setControlPointSelection([
          ...controlPointSelection,
          { id: newControlPoint.id, controlPoint: newControlPoint, color: nextColor },
        ]);
      }
    }
  };

  const controlPointsWithPassages = controlPoints.map(controlPoint => {
    const hasPassages = !!controlPointPassages.find(
      controlPointPassage => controlPointPassage.controlPointName === controlPoint.name,
    );

    const amountOfPassages: number = controlPointPassages.filter(
      controlPointPassage => controlPointPassage.controlPointName === controlPoint.name,
    ).length;

    const scalingValue = 3;
    const scaling = 1 + (amountOfPassages / controlPointPassages.length) * scalingValue;

    return { ...controlPoint, hasPassages: hasPassages, scaling: scaling };
  });

  const selectedControlPointInfo = controlPoints
    .filter(controlPoint => controlPointSelection.find(selection => selection.id === controlPoint.id))
    .map(controlPoint => {
      const passages = controlPointPassages.filter(passage => passage.controlPointName == controlPoint.name);
      const selectedColor = controlPointSelection.find(selection => selection.id === controlPoint.id)?.color;

      return {
        id: controlPoint.id,
        controlPoint: controlPoint,
        color: selectedColor ? selectedColor : "white",
        passages: passages,
      };
    });

  const multitudeControlPointsPassagesForSelectedPoint = controlPointPassages.filter(controlPointPassage => {
    const multitudeControlPointsNames = controlPointSelection.map(selection => selection.controlPoint.name);

    return multitudeControlPointsNames.includes(controlPointPassage.controlPointName);
  });

  const hideNoPassagesControlPoint = () => {
    return controlPointsWithPassages.filter(x => x.hasPassages);
  };

  const DevelopmentAreaPicker = () => (
    <SelectField
      options={developmentAreas.map(developmentArea => {
        return {
          id: developmentArea.developmentArea,
          value: `${developmentArea.developmentArea} - ${developmentArea.name}`,
        };
      })}
      onSelect={selected => {
        setDevelopmentAreaId(selected?.id);
      }}
      selectedId={developmentAreaId}
      label={translate("CHOOSE_AREA")}
    ></SelectField>
  );

  const MapSection = () => (
    <Map
      controlPoints={
        hideEmptyControlPoint && hideNoPassagesControlPoint().length > 0
          ? hideNoPassagesControlPoint()
          : controlPointsWithPassages
      }
      onSelect={onMapSelect}
      defaultZoom={defaultZoomLevel}
      selection={controlPointSelection}
    />
  );

  const FilterSection = () => (
    <>
      <div className={styles.selectionLabel}>
        {(() => {
          const firstSelectedControlPoint = controlPoints.find(
            controlPoint => controlPoint.id == controlPointSelection[0]?.id,
          );

          switch (controlPointSelection.length) {
            case 0:
              return null;
            case 1:
              return (
                <div>
                  {firstSelectedControlPoint?.code} - {firstSelectedControlPoint?.name}
                </div>
              );

            default:
              return <div>{translate("MULTIPLE_SELECTED")}</div>;
          }
        })()}

        <Button customCssClass={styles.itemButton} onClick={onShowGraphToggle}>
          {showGraph ? translate("SHOW_TABLE") : translate("SHOW_GRAPH")}
        </Button>
      </div>
      <div>
        <InputField
          customCssClass={styles.input}
          type={DATA_TYPE.DATE}
          label={translate("FROM_DATE")}
          labelAlwaysTop
          value={filters.fromDate}
          onChange={(option: React.ChangeEvent<HTMLInputElement>) => onFilterChange("fromDate", option.target.value)}
        />
      </div>
      {multipleDates ? (
        <div>
          <InputField
            customCssClass={styles.input}
            type={DATA_TYPE.DATE}
            label={translate("TO_DATE")}
            labelAlwaysTop
            value={filters.toDate}
            onChange={(option: React.ChangeEvent<HTMLInputElement>) => onFilterChange("toDate", option.target.value)}
          />
        </div>
      ) : null}
      <div>
        <Checkbox label={translate("DATE_INTERVAL")} onChange={() => onDateIntervalChange()} checked={multipleDates} />
        <Checkbox
          label={translate("HIDE_NO_TRAFFIC")}
          onChange={() => setHideEmptyControlPoint(!hideEmptyControlPoint)}
          checked={hideEmptyControlPoint}
        />
        {showGraph && controlPointSelection[0] ? (
          <Checkbox
            label={translate("SHOW_TOTAL_GRAPH")}
            onChange={() => setGraphShowTotal(!graphShowTotal)}
            checked={graphShowTotal}
          />
        ) : null}
      </div>
    </>
  );

  const PassagesSection = () =>
    showGraph ? (
      controlPointSelection[0] ? (
        graphShowTotal ? (
          <ControlPointGraph controlPointPassages={multitudeControlPointsPassagesForSelectedPoint} />
        ) : (
          <MultiControlPointGraph controlPointInfo={selectedControlPointInfo} />
        )
      ) : (
        <AllControlPointsGraph controlPointPassages={controlPointPassages} />
      )
    ) : (
      <ControlPointPassageTable
        controlPointPassages={
          controlPointSelection[0] ? multitudeControlPointsPassagesForSelectedPoint : controlPointPassages
        }
        singlePoint={!!controlPointSelection[0]}
        showDate={multipleDates}
      />
    );

  return (
    <div className={styles.container}>
      <div className={styles.picker}>
        <DevelopmentAreaPicker />
      </div>
      {controlPoints.length > 0 ? (
        <>
          <div className={styles.map}>
            <MapSection />
          </div>
          <div className={styles.filter}>
            <FilterSection />
          </div>
          <div className={styles.passages}>
            <PassagesSection />
          </div>
        </>
      ) : (
        <p>{translate("NO_TRAFFIC_NODES_FOUND")}</p>
      )}
    </div>
  );
};

export default TrafficNodesPage;
