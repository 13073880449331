import { useEffect, useRef } from "react";
import useForceUpdate from "../../../hooks/useForceUpdate";
import { FulfilledUpdateMethod } from "../../dataTypes";
import { ControlPointsFilter } from "../dataTypes";
import useLazyGetControlPoints from "./useLazyGetControlPoints";

const useGetControlPoints = ({
  filter,
  fulfilledUpdateMethod,
}: {
  filter?: ControlPointsFilter;
  fulfilledUpdateMethod?: FulfilledUpdateMethod;
}) => {
  const {
    isLoading: isLazyLoading,
    fetchControlPoints,
    ...rest
  } = useLazyGetControlPoints({
    filter,
    fulfilledUpdateMethod,
  });

  const forceUpdate = useForceUpdate();

  const initiationStatus = useRef<"initiating" | "initiated">();

  useEffect(() => {
    const initiateControlPoints = async () => {
      if (!initiationStatus.current) {
        initiationStatus.current = "initiating";
        forceUpdate();
        await fetchControlPoints();
        initiationStatus.current = "initiated";
      }
    };

    initiateControlPoints();
  }, [fetchControlPoints, forceUpdate]);

  const isLoading = !initiationStatus.current || isLazyLoading;

  return { ...rest, isLoading, refetchControlPoints: fetchControlPoints };
};

export default useGetControlPoints;
