import { URL } from "@myloc/myloc-utils";

const endpoint = (url: string, params?: any, version = "/construction/v1") => {
  const root = () => {
    let domain;
    const { host, protocol } = window.location;

    if (process.env.NODE_ENV === "development" || host === process.env.REACT_APP_URL) {
      domain = process.env.REACT_APP_API_DOMAIN;
    } else {
      domain = protocol + "//" + host;
    }

    const baseURI = (domain ?? "") + process.env.REACT_APP_API_PATH;

    return baseURI;
  };

  const templates = { ...params };

  const preparedUrl = url.replace(/{([^}]+)}/g, (_, key) => templates[key]);

  const baseURI = root();
  const fullPath = `${baseURI}${version}${preparedUrl}`;
  const uri = new URL(fullPath);

  return uri;
};

export const api = {
  account: {
    authentication: () => endpoint("/authentication"),
    accessKeyAuthentication: () => endpoint("/authentication/access-key"),
  },
  developmentArea: {
    getDevelopmentAreas: () => endpoint("/development-areas/get-development-areas"),
  },
  resource: {
    getResources: () => endpoint("/resources/get-resources"),
  },
  resourceCalendar: {
    getCalendarEvents: () => endpoint("/resource-calendar-events/get-calendar-events"),
  },
  resourceCalendarSettings: {
    getResourceCalendarSettings: () => endpoint("/resource-calendar-events/get-calendar-settings"),
  },
  slideshow: {
    content: () => endpoint("/slideshow/getSlideshow"),
  },
  file: {
    file: (fileId: string) => endpoint("/files/{fileId}", { fileId }),
  },
  pageContent: {
    currentContent: () => endpoint("/page-content"),
    files: (pageContentId: string) => endpoint("/page-contents/{pageContentId}/files", { pageContentId }),
    hyperlinks: (pageContentId: string) => endpoint("/page-contents/{pageContentId}/hyperlinks", { pageContentId }),
  },
  password: {
    password: () => endpoint("/password"),
    passwordRules: () => endpoint("/password/rules"),
  },
  user: {
    currentUser: () => endpoint("/user"),
    nextAccessMessage: () => endpoint("/user/access-message"),
    accessMessage: (messageId: string) => endpoint("/user/access-messages/{messageId}", { messageId }),
  },
  controlPoint: {
    controlPoints: () => endpoint("/control-points"),
    controlPoint: (controlPointId: string) => endpoint("/control-points/{controlPointId}", { controlPointId }),
    passages: (controlPointId: string) => endpoint("/control-points/{controlPointId}/passages", { controlPointId }),
  },
  controlPointPassage: {
    controlPointsPassages: () => endpoint("/control-point-passages"),
    controlPointPassage: (controlPointPassageId: string) =>
      endpoint("/control-point-passages/{controlPointPassageId}", { controlPointPassageId }),
  },
};

const allowRedirectionToAfterLogin = (desiredPage: string) => {
  //Not allowed pages to restrict redirection to after login - only part of url is needed
  const PASSWORD = "/password";

  const DISALLOWED_PARTS_OF_PATH = [PASSWORD];

  //If returned value from findIndex >= 0, a disallowed part of path has been found among the DISALLOWED urls
  const posInDisallowedArray = DISALLOWED_PARTS_OF_PATH.findIndex(element => {
    return desiredPage.indexOf(element) >= 0;
  });

  //Return allowed (true) if no positsion in array was found (-1)
  return posInDisallowedArray === -1;
};

export const MYLOC = {
  webpageUrl: new URL("https://myloc.se"),
  cookieIntegrityUrl: new URL("https://myloc.se/sv/gdpr/"),
  contactEmail: new URL("info@myloc.se"),
  contactPhone: new URL("040-6304643"),
  supportEmail: new URL("support@myloc.se"),
  address: {
    street: "Kärleksgatan 1A",
    postalNo: "211 45",
    city: "Malmö",
    county: "Skåne",
  },
} as const;

export const pages = {
  allowRedirectionToAfterLogin,
};
