const pages = Object.freeze({
  HOME: "/",
  EMBEDDED_CONTROL_POINTS: "/embedded/control-points",
  SLIDESHOW: "/slideshow",
  RESOURCECALENDAR: "/embedded/resource-calendar",
  REGISTER: "account/register",
  RESET_PASSWORD: {
    PATH: "/password/reset",
    NAME: "RESET_PASSWORD_TITLE",
  },
} as const);

export default pages;
