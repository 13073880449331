import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { PaginationFilter } from "../../utils/pagination";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  FulfilledUpdateMethod,
  FULFILLED_UPDATE_METHOD,
  PaginationResponse,
  REQUEST_STATE,
} from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import { Resource, resourceSliceName, ResponseResource } from "../dataTypes";
import { resourceAdapter } from "../resourceAdapter";

const getResources = async (dispatch: AppDispatch, { filter = {} }: { filter?: Readonly<PaginationFilter> }) => {
  const url = api.resource.getResources();

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, filter, await defaultRestOptions({ dispatch }));

  // TODO - Remove when we find the mysterious backend error or if it disappear in 2.24
  if (response.isOk()) return response;

  return await sendRequest(request, filter, await defaultRestOptions({ dispatch }));
};

export const fetchResources = createAsyncThunk<
  PaginationResponse<ResponseResource>,
  { filter?: Readonly<PaginationFilter>; fulfilledUpdateMethod: FulfilledUpdateMethod }
>(resourceSliceName + "/fetchResources", getResources);

export const addFetchResourcesReducers = (
  builder: EntityActionReducerBuilderInterface<Resource, AdditionalPaginationState>,
) => {
  builder.addCase(fetchResources.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessage = undefined;
  });
  builder.addCase(fetchResources.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod },
      },
    } = action;

    const { count, page } = payload;
    const resources = page.map(mapFulfilledResponseObject);

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        resourceAdapter.setAll(state, resources);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        resourceAdapter.upsertMany(state, resources);
        break;
    }

    state.count = count;

    state.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(fetchResources.rejected, (state, action) => {
    state.requestState = REQUEST_STATE.REJECTED;
    state.errorMessage = action.payload?.message;
  });
};
