import { useCallback, useEffect, useRef } from "react";
import { PaginationFilter } from "../../utils/pagination";
import { FulfilledUpdateMethod, REQUEST_STATE } from "../../dataTypes";
import { useAppSelector } from "../../../app/hooks";
import {
  selectCount,
  selectErrorMessage,
  selectRequestState,
  selectDevelopmentAreas,
} from "../developmentAreaSelectors";
import useFetchDevelopmentAreas from "./useFetchDevelopmentAreas";

const useLazyGetDevelopmentAreas = ({
  filter,
  fulfilledUpdateMethod,
  loadIfNotInitialized = false,
}: {
  filter?: Readonly<PaginationFilter>;
  fulfilledUpdateMethod: FulfilledUpdateMethod;
  loadIfNotInitialized?: boolean;
}) => {
  const fetchDevelopmentAreas = useFetchDevelopmentAreas();
  const hasInitalized = useRef(false);

  const requestState = useAppSelector(selectRequestState);
  const errorMessage = useAppSelector(selectErrorMessage);

  const developmentAreas = useAppSelector(selectDevelopmentAreas);

  const count = useAppSelector(selectCount);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isError = requestState === REQUEST_STATE.REJECTED;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;

  const loadDevelopmentAreas = useCallback(async () => {
    await fetchDevelopmentAreas({ filter, fulfilledUpdateMethod });
  }, [fetchDevelopmentAreas, filter, fulfilledUpdateMethod]);

  useEffect(() => {
    hasInitalized.current = false;
  }, [filter]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized && !hasInitalized.current) {
      hasInitalized.current = true;
      loadDevelopmentAreas();
    }
  }, [isUninitialized, loadIfNotInitialized, loadDevelopmentAreas]);

  return {
    developmentAreas,
    count,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    errorMessage,
    fetchDevelopmentAreas: loadDevelopmentAreas,
  };
};

export default useLazyGetDevelopmentAreas;
