import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { developmentAreaSliceName } from "./dataTypes";
import { addFetchDevelopmentAreasReducers } from "./reducers/fetchDevelopmentAreas";
import { developmentAreaAdapter } from "./developmentAreaAdapter";

const slice = createSlice({
  name: developmentAreaSliceName,
  initialState: developmentAreaAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addFetchDevelopmentAreasReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
