import { AdditionalRequestState, IdObject } from "../dataTypes";

export const developmentAreaSliceName = "developmentArea";

export interface ResponseDevelopmentArea extends IdObject {
  developmentArea: string;
  name: string;
  defaultZoomLevel?: number;
}

export interface DevelopmentArea extends ResponseDevelopmentArea, AdditionalRequestState {}
