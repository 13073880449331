import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { PaginationFilter } from "../../utils/pagination";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  FulfilledUpdateMethod,
  FULFILLED_UPDATE_METHOD,
  PaginationResponse,
  REQUEST_STATE,
} from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import { DevelopmentArea, developmentAreaSliceName, ResponseDevelopmentArea } from "../dataTypes";
import { developmentAreaAdapter } from "../developmentAreaAdapter";

const getDevelopmentAreas = async (dispatch: AppDispatch, { filter = {} }: { filter?: Readonly<PaginationFilter> }) => {
  const url = api.developmentArea.getDevelopmentAreas();

  const request = new Request(url, Method.POST);

  return await sendRequest(request, filter, await defaultRestOptions({ dispatch }));
};

export const fetchDevelopmentAreas = createAsyncThunk<
  PaginationResponse<ResponseDevelopmentArea>,
  { filter?: Readonly<PaginationFilter>; fulfilledUpdateMethod: FulfilledUpdateMethod }
>(developmentAreaSliceName + "/fetchDevelopmentAreas", getDevelopmentAreas);

export const addFetchDevelopmentAreasReducers = (
  builder: EntityActionReducerBuilderInterface<DevelopmentArea, AdditionalPaginationState>,
) => {
  builder.addCase(fetchDevelopmentAreas.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessage = undefined;
  });
  builder.addCase(fetchDevelopmentAreas.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod },
      },
    } = action;

    const { count, page } = payload;
    const developmentAreas = page.map(mapFulfilledResponseObject);

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        developmentAreaAdapter.setAll(state, developmentAreas);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        developmentAreaAdapter.upsertMany(state, developmentAreas);
        break;
    }

    state.count = count;

    state.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(fetchDevelopmentAreas.rejected, (state, action) => {
    state.requestState = REQUEST_STATE.REJECTED;
    state.errorMessage = action.payload?.message;
  });
};
