import PropTypes from "prop-types";

import differenceInMinutes from "date-fns/differenceInMinutes";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import svLocale from "@fullcalendar/core/locales/sv";

import SmallCalendarEvent from "./CalendarSlideshow/SmallCalendarEvent";
import NormalCalendarEvent from "./CalendarSlideshow/NormalCalendarEvent";
import { CalendarEventInfo, Slides } from "../../dataTypes";

import styles from "./Calendar.module.scss";

const TIME_OPTIONS = {
  CALENDAR_TYPE_INTERVAL: 90,
  SLOT_DURATION_INTERVAL: 9 * 60,
  SLOT_DURATION_LONG_DAY: "01:00",
  SLOT_DURATION_SHORT_DAY: "00:30",
} as const;

const Calendar = ({
  height,
  headerHeight,
  slide,
  calendarEvent,
}: {
  height: string;
  headerHeight: string;
  slide: Slides;
  calendarEvent: CalendarEventInfo[];
}) => {
  const timeDuration = (startDate: Date | string, endDate: Date | string) => {
    if (typeof startDate === "string") startDate = new Date(`1995-12-17T${startDate}`);
    if (typeof endDate === "string") endDate = new Date(`1995-12-17T${endDate}`);
    return differenceInMinutes(endDate, startDate);
  };

  return (
    <div className={styles.calendar}>
      <h2 className={styles.title}>{slide.projectId && `${slide.projectId} - ${slide.projectName}`}</h2>
      <FullCalendar
        plugins={[timeGridPlugin, dayGridPlugin]}
        initialView={slide.viewMode}
        weekends={slide.showWeekends}
        initialDate={slide.startDate}
        locale={svLocale}
        headerToolbar={false}
        allDaySlot={true}
        firstDay={1}
        slotMinTime={slide.workDayStart}
        slotMaxTime={slide.workDayEnd}
        height={window.innerHeight > 600 ? `calc(${height} - ${headerHeight} - 20px)` : height}
        expandRows={true}
        nowIndicator={true}
        slotDuration={TIME_OPTIONS.SLOT_DURATION_SHORT_DAY}
        events={calendarEvent}
        eventContent={function (arg) {
          return (
            <>
              {arg.event.start && arg.event.end ? (
                timeDuration(arg.event.start, arg.event.end) > TIME_OPTIONS.CALENDAR_TYPE_INTERVAL ? (
                  <NormalCalendarEvent arg={arg} />
                ) : (
                  <SmallCalendarEvent arg={arg} />
                )
              ) : (
                <SmallCalendarEvent arg={arg} />
              )}
            </>
          );
        }}
      />
    </div>
  );
};

Calendar.propTypes = {
  height: PropTypes.string.isRequired,
  headerHeight: PropTypes.string.isRequired,
  workDayStart: PropTypes.string.isRequired,
  workDayEnd: PropTypes.string.isRequired,
  slide: PropTypes.object.isRequired,
  calendarEvent: PropTypes.arrayOf(
    PropTypes.shape({
      end: PropTypes.string.isRequired,
      start: PropTypes.string.isRequired,
      pattern: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      headerColor: PropTypes.string.isRequired,
      allDay: PropTypes.bool.isRequired,
      icon: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
      backgroundColor: PropTypes.string.isRequired,
      bodyText: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Calendar;
